<template>
	<div class="page-width">
		<div class="flex flex_align_center">
			<div class="top_container">
				<input
					v-model="position_applicant_code"
					type="text"
					class="margin_0"
					placeholder="Search position applicant code"
				/>
				<a
					@click="filterByPositionApplicantCode"
					class="button is-info"
					>Submit</a
				>
			</div>
			<div class="top_container">
				<input
					v-model="position_title"
					type="text"
					class="margin_0"
					placeholder="Search by title"
				/>
				<a
					@click="filterByTitle"
					class="button is-info"
					>Submit</a
				>
			</div>
		</div>
		<datatable title="Positions" :show_delete="false" @edit_click="editClick" @boost="boostPosition" @slots="goToPositionSlots" :operations="operations" 
				   :response="data" :fields="fields" :filters="filters" :is_createable="false" @publish="publishPosition">
		</datatable>
	</div>
</template>
<script>
	import { mapMutations } from 'vuex'
	import FiltersHelper from '@/classes/filters_helper.js'
	import Datatable from '@/components/Datatable'
	export default {
		name: 'companies',
		data(){
			let filters_helper = new FiltersHelper()
			return {
				position_applicant_code: this.$route.query.position_applicant_code,
				position_title: this.$route.query.position_title,
				filters_helper,
				fields: [
				    {
				      name: 'Status',
				      value: 'status'
				    },
				    {
				      name: 'Title',
				      value: 'title',
				      component: 'LinkEntity',
				      additional_data: {
				      	resource_type: 'Position'
				      }
				    },
				    {
				    	name: 'env',
				    	value: 'env'
				    },
				    {
				      name: 'Priority',
				      value: 'priority'
				    },
				    {
				      name: 'Publish Outsources',
				      value: 'publish_outsources',
				    },
				    {
				      name: 'position_applicant_code',
				      value: 'position_applicant_code',
				    },
						{
				      name: 'Last Publish Date',
				      value: 'last_publish_date',
				      is_time: true
				    },
				    {
				      name: 'created_at',
				      value: 'created_at',
				      is_time: true
				    },
				],
				filters: [
			        {
			          type: 'single',
			          designator: 'env',
			          options: filters_helper.getEnvsAsFilters()
			        },
					{
					  type: 'single',
					  designator: 'status',
					  options: [
					  	{
					  		text: 'active',
					  		value: 'active'
					  	},
					  	{
					  		text: 'expired',
					  		value: 'expired'
					  	}
					  ]
					},
				],
				data: [],
				operations: [{ name: 'BOOST' }, { name: 'Slots' }, { name: 'Publish' }]
			}
		},
		created(){
			this.$network_utils.getPositions({
				...this.filters_helper.getServerCallFilters(this.filters),
				env_position_id: this.$route.query.env_position_id,
				env: this.$route.query.env,
				position_applicant_code: this.$route.query.position_applicant_code,
				title: this.$route.query.position_title
			}).then(response => {
          		response = Array.isArray(response) ? response : [response]
				this.data = response
		        this.data.map(outsource_position => {
		          	outsource_position.priority = this.getPriorityTextByValue(outsource_position.priority)
	  		        if (outsource_position.has_active_slot) {
		          		outsource_position.status = '🍏 ' + outsource_position.status
			        }
			        return outsource_position
		        })
		        this.sortPositions()
			})
		},
		methods: {
			editClick(data){
				this.createModal({
					designator: 'position-modal',
					component_name: 'PositionForm',
					data,
					modal_title: data.title
				})
			},
			getPriorityTextByValue (value) {
				if (value == '2') {
					return 'עדיפות נמוכה'
				}
				if (value == '5') {
					return 'עדיפות בינונית'
				}
				if (value == '10') {
					return 'עדיפות גבוהה'
				}
				if (value == '60') {
					return 'BOOSTED'
				}
				return ''
			},
			boostPosition(data){
		      	if (confirm('Are you sure you want to boost?')) {
			   		this.$network_utils.boostPosition({ env: data.env, env_position_id: data.env_position_id }).then(response => {
			   			this.$utils.emit('show_toast', { text: 'Boosted' })
			   		}).catch(() => {
			          this.$utils.emit('show_toast', { text: 'שגיאה' })
			        })
		   		}
			},
		    sortPositions () {
		      this.data.sort((a, b) => {
		        let a_time = new Date(b.created_at).getTime()
		        let b_time = new Date(a.created_at).getTime()
		        return ((b.has_active_slot - a.has_active_slot) || ((b.priority * 1) - (a.priority * 1)) || (a_time - b_time))
		      })
		    },
		    filterByPositionApplicantCode(){
				let query = { ...this.$route.query };
				query.position_applicant_code = this.position_applicant_code.length > 0 ? this.position_applicant_code : undefined
				this.$router.push({ name: "positions", query });
		    },
		    filterByTitle(){
				let query = { ...this.$route.query };
				query.position_title = this.position_title.length > 0 ? this.position_title : undefined
				this.$router.push({ name: "positions", query });
		    },
		    goToPositionSlots(position){
		    	this.$router.push({ name: 'slots', query: { position_id: position.id } })
		    },
		    publishPosition(data){
				this.createModal({
					designator: 'publish-position-modal',
					component_name: 'PublishPositionForm',
					position: data,
					modal_title: `Publish Position - ${data.title}`
				})
		    },
			...mapMutations('modals', ['createModal'])
		},
		components: {
			Datatable
		}
	}
</script>
<style scoped>
	.top_container {
		margin-right: 20px;
	}
	.top_container .button {
		font-size: 13px;
		margin-left: 10px;
	}
</style>